.header {
    padding: 1.2rem;
    background-color: #272727;
    position: fixed;
    width: 100%;
    z-index: 10;
    border-radius: 0px 0px 15px 15px;
    border-top: 0;
    box-shadow: 1px 1px 20px #d33a3a;
}
.header > h1 {
    font-size: 28px;
    color: #e8e8e8;

}

.header > button {
    background-color: #d33a3a;
    color: #e8e8e8;
    border: none;
    padding: 1em;
    border-radius: 100px;
    font-weight: 700;
    margin-top: 1rem;
    display: none;
}

@media (max-width:768px) {
    .header > button  {
        display: flex;
    }
    .header > h1 {
        font-size: 18px;
    }
   
}