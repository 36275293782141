* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: black;
    font-family: Arial, Helvetica, sans-serif;
}

body.lock {
    overflow: hidden;
}


/* Container */
.containerRow {
    display: flex;
    padding: 6rem 1rem;
}

.containerColumnCharacter {
    background-color: #272727;
    width: 25%;
    padding: 1rem;
    border-radius: 20px;
    margin-right: 1rem;
    min-width: 300px;
}



.containerColumnCharacter > h2 {
    font-size: 16px;
    color: #e8e8e8;
    font-weight: 600;
}
.containerColumnCharacter > ul {
    margin-top: 1rem;
}
.containerColumnCharacter > ul > li {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 0.7rem;
}
.containerColumnCharacter > ul > li > a {
    background-color: #e8e8e8;
    border:none;
    padding: 1em;
    width: 100%;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #272727;
}
.containerColumnCharacter > ul > li > a.active {
    transform: scale(0.95);
    background-color: #d33a3a;
    color: #e8e8e8;
}
.containerColumnCharacter > ul > li:hover > a {
    transform: scale(0.95);
    background-color: #d33a3a;
    color: #e8e8e8;
}
.containerColumnCharacter > ul > li:last-child {
    margin-bottom: 0rem;
}

.containerColumnInfo {
    background-color: #272727;
    width: 75%;
    padding: 1.5rem;
    border-radius: 20px;
    max-width: 865px;
}

.containerColumnInfo h2 {
    font-size: 24px;
    color: #e8e8e8;
    font-weight: 600;
}

.totalInfoRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.5rem;
}

.totalInfoColumn {
    width: 185px;
    height: 185px;
    background-color:#e8e8e8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin: 0.6rem;
    padding: 1em;
    transition: 0.3s ease-in-out;
}
.totalInfoColumn:hover {
    transform: scale(1.1);
    box-shadow: 1px 1px 60px  #d33a3a;
}
.totalInfoColumn span, p{
    width: 100%;
    text-align: center;
}
.totalInfoColumn span {
    font-size: 32px;
    font-weight: 700;
    color: #d33a3a;
    animation-name: animNum;
    animation-duration: 2s;
    opacity: 0;
    animation-fill-mode: both;
}
@keyframes animNum {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.totalInfoColumn p {
    font-size: 14px;
    font-weight: 300;
}







.dashboardCharactersRow {
    margin: 0.7rem 0;
}
.dashboardCharactersRow > h2{
    color: #e8e8e8;
    font-size: 20px;
}
.dashboardCharactersRow > span {
    font-size: 14px;
    color: #e8e8e8;
}

.goBack {
    color: #d33a3a;
}

.infoCharacterSingle {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.infoCharacterSingle__img {
    padding-right: 2rem;
    padding-bottom: 2rem;
    width: 300px;
    opacity: 0;

}
.infoCharacterSingle__img.active {
    opacity: 1;
    transition: 1s;
}

.infoCharacterSingle__info {
    width: 60%;
}

.infoCharacterSingle img {
    width: 250px;
    height: 320px;
    min-height: 320px;
    min-width: 250px;
    object-fit: cover;
    border-radius: 10px;
    border: 4px solid #d33a3a;
    
}
.infoCharacterSingle ul > li:first-child {
padding-top: 0;
}
.infoCharacterSingle ul > li {
    list-style: none;
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: #e8e8e8;
    border-bottom: 1px solid #e8e8e814;
}

@keyframes anim-info-char {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width:768px) {
    .containerRow {
        flex-wrap: wrap;
        padding: 8rem 1rem;
    }
    .containerColumnCharacter {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        overflow: auto;
        border-radius: 0;
        background-color: #ffffff;
        z-index:11;
        opacity: 0;
        visibility: hidden;
        padding-bottom: 10rem;
    }
    .containerColumnCharacter.active  {
        opacity: 1;
        visibility: visible;
    }
    .containerColumnCharacter > h2 {
        color: #272727;
    }
    .containerColumnInfo {
        width: 100%;
    }
    .infoCharacterSingle__info {
        width: 100%;
    }
}